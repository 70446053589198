import BaiduFaceBaseService from './BaiduFaceBaseService';

export class BaiduFaceApi extends BaiduFaceBaseService {
    constructor() {
        super("");
    }


    /**
* 注册人脸信息
* @param image 
* @param groupId 
*/
    public registerFaceMode(image: any, groupId: any): Promise<any> {
        const params = {
            image: image,
            groupId: groupId
        }
        return this.request('face/register', this.rest.METHOD_POST, params);
    }

    /**
   * 根据faceid获取人员信息
   */
    public getUserInfoByFaceId(faceId: any): Promise<any> {
        let params = {
            'faceId': faceId
        }
        return this.request('user/getUserInfoByFaceId', this.rest.METHOD_GET, params);
    }

    /**
     * 获取待接待访客列表
    */
    //  public addVisitor(params: any): Promise<any> {
    //     return this.request('save', this.rest.METHOD_POST, params);
    // }

    /**
     * 获取访客信息
    */
    //  public visitorDetails(params: any): Promise<any> {
    //     return this.request('detail', this.rest.METHOD_GET, params);
    // }

}