
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";

import User from "@/models/task/User";
import { VisitorService } from "@/services/visitor/VisitorService";
import { BaiduFaceService } from "@/services/baiduface/BaiduFaceService";
import {
  FileAttendanceHelper,
  UploadStatus,
  UploadMessage,
} from "@/utils/FileAttendanceHelper";
import { UserInfoUpdateService } from "@/services/mine/UserInfoUpdateService";

export default defineComponent({
  name: "AddVisitor",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonInput,
    Header,
  },
  data() {
    const router = useRouter();
    const taskInfo: any = {
      priority: "0",
      projectId: "",
      executorUsers: [],
      partUsers: [],
    };
    const currentUser: User = SystemUtils.loginUser;
    const user: User = {
      id: "",
      pernr: "",
    };

    const fileHelper = new FileAttendanceHelper();

    return {
      fileHelper: fileHelper,
      photosMessage: new Array<any>(),
      folderId_photo: "",
      userInfo: user,
      UserInfoUpdateService: new UserInfoUpdateService(),
      prePath: "/ematrixjct/api",

      visitorService: new VisitorService(),
      baiduFaceService: new BaiduFaceService(),
      router: useRouter(),

      fileAttendanceHelper: new FileAttendanceHelper(),

      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      //头像上传分组标识
      photoGroup: "antiepidemic",

      params: {
        id: "",
        name: "",
        mobilePhone: "",
        idNumber: "",
        orgName: "",
        planTime: "",
        reason: "",
        source: "2",
        userName: currentUser.name,
        userPhone: currentUser.phone,
        faceId: "",
        photo: "",
      },

      paramsJson: "",

      defaultPhoto: "", //默认头像
    };
  },
  created() {
    this.initPhoto();
  },
  mounted() {
    let nowDate = new Date();
    this.params.planTime = moment(nowDate).format("YYYY-MM-DD HH:mm:ss");
  },
  methods: {
    initPhoto() {
      //头像
      this.photosMessage = [
        {
          id: "",
          url: this.defaultPhoto,
          status: UploadStatus.Done,
          message: UploadMessage.Done,
          isImage: true,
        },
      ];
      // if (this.userInfo.photo != undefined && this.userInfo.photo != null) {
      //   this.checkFolderExists(this.folderId_photo, this.photosMessage, "picture");
      // }
    },
    //上传头像
    updatePhoto() {
      this.photosMessage[0].status = UploadStatus.Uploading;
      this.photosMessage[0].message = UploadMessage.Uploading;
      this.fileHelper.openCameraBase64().then(
        (res: any) => {
          //请求接口,进行注册
          this.photosMessage[0].url = "data:image/jpg;base64," + res;
          this.baiduFaceService.registerFaceMode(res, "antiepidemic").then(
            (result: any) => {
              if (result) {
                this.photosMessage[0].id = result.faceId;
                console.log("人脸标识：" + result.faceId);
                this.getUserInfoByFaceId(
                  result.faceId,
                  this.photosMessage[0].url
                );
              } else {
                this.initPhoto();
              }
            },
            (err: any) => {
              this.initPhoto();
              Utils.presentToastWarning("人脸识别失败");
            }
          );
        },
        (e: any) => {
          this.initPhoto();
          Utils.presentToastWarning("拍照失败");
        }
      );
    },

    getUserInfoByFaceId(faceId: string, faceRes: any) {
      this.baiduFaceService
        .getUserInfoByFaceId(faceId)
        .then(
          (res: any) => {
            console.log(res);
            if (res) {
              Utils.presentToastWarning("公司人员不可进行访客登记");
              this.initPhoto();
            } else {
              this.photosMessage[0].status = UploadStatus.Done;
              this.photosMessage[0].message = UploadMessage.Done;

              this.params.faceId = faceId;
              this.params.photo = faceRes;
            }
          },
          (e: any) => {
            console.log(e);
          }
        )
        .catch((c: any) => {
          console.log(c);
        });
    },

    bindSubmitHandler() {
      if (this.params.name == null || this.params.name == "") {
        Utils.presentToastWarning("请输入来访人员");
        return;
      }
      if (this.params.mobilePhone == null || this.params.mobilePhone == "") {
        Utils.presentToastWarning("请输入电话");
        return;
      }
      if (this.params.planTime == null || this.params.planTime == "") {
        Utils.presentToastWarning("请选择计划时间");
        return;
      }
      this.params.planTime = this.params.planTime
        .replace("T", " ")
        .substring(0, 19);
      if (this.params.reason == null || this.params.reason == "") {
        Utils.presentToastWarning("请输入事由");
        return;
      }

      // this.paramsJson = JSON.stringify(this.params);

      this.visitorService
        .addVisitor(this.params)
        .then((res: any) => {
          if (res) {
            this.$emit("on-back");
            this.router.go(-1);
          }
        })
        .catch(() => {});
    },
  },
});
