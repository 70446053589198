import { BaiduFaceApi } from '@/api/baiduface/BaiduFaceApi';

export class BaiduFaceService {
    private api = new BaiduFaceApi();

    public registerFaceMode(imgData: any,groupId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.registerFaceMode(imgData,groupId).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //根据人脸id
    public getUserInfoByFaceId(faceId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getUserInfoByFaceId(faceId).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //添加访客人员
    // public addVisitor(params: any): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.api.addVisitor(params).then((res: any) => {
    //             resolve(res.data);
    //         }).catch((err: any) => {
    //             reject(err);
    //         });
    //     });
    // }

    //访客人员信息
    // public visitorDetails(params: any): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.api.visitorDetails(params).then((res: any) => {
    //             resolve(res.data);
    //         }).catch((err: any) => {
    //             reject(err);
    //         });
    //     });
    // }
}